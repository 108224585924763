<template>
 <div class="demo">
  <dv-border-box-1>
   <div class="barBox">
    <div class="flex bar_tool flex-between">
     <a class="shop">分店数 {{ brandRoom.sumBranchNumber }}</a>
     <a class="room">客房数 {{ brandRoom.sumRoomNumber }}</a>
    </div>
    <div class="barChart">
     <div id="barCharts" :class="{ echartNoData: !hasFlag&&!showCharts }"></div>
    </div>
   </div>
  </dv-border-box-1>
 </div>
</template>

<script>
import { GET_BRAND_ROOM, findBrandRegionNumber2 } from "@/api";
import {
 title_Font,
 x_Font,
 y_Font,
 w_Font,
 h_Font,
 line_Font,
 symbolSize_Font,
 map_Font,
 map_period,
 map_scale,
 map_shadowBlur,
 mapTip,
 line_h_Font,
} from "./font";
var colorList = (a = "#caf982", b = "#06DBF5") => {
 return {
  type: "linear",
  x: 0,
  y: 0,
  x2: 1,
  y2: 0,
  colorStops: [
   {
    offset: 0,
    color: a, // 0% 处的颜色
   },
   {
    offset: 1,
    color: b, // 100% 处的颜色
   },
  ],
  globalCoord: false, // 缺省为 false
 };
};
var label = {
 stack: "分店数",
 type: "bar",
 xAxisIndex: 1,
 label: {
  normal: {
   color: "#fff",
   textStyle: {
    fontSize: x_Font(),
   },
   position: "inside",
   show: true,
  },
 },
};
var kfModel = {
 label: {
  normal: {
   color: "#fff",
   textStyle: {
    fontSize: x_Font(),
   },
   position: "inside",
   show: true,
  },
 },
 itemStyle: {
  color: "#F59A23",
  fontSize: x_Font(),
 },
};
var type1Lable = {
 z: 1.5,
 type: "bar",
 label: {
  normal: {
   color: "#fff",
   textStyle: {
    fontSize: x_Font(),
   },
   position: "right",
   show: true,
  },
 },
};
var barModel = {
 grid: {
  left: "10%",
  right: "10%",
  bottom: "10%",
  top: "20%",
  // containLabel: true
 },
 tooltip: {
  //提示框组件
  trigger: "axis",
  // formatter: "{b}<br />{a0}: {c0}<br />{a1}: {c1}",
  axisPointer: {
   type: "shadow",
   label: {
    backgroundColor: "#6a7985",
   },
  },
  textStyle: {
   color: "#fff",
   fontStyle: "normal",
   fontFamily: "微软雅黑",
   fontSize: x_Font(),
  },
 },
};
var yAxisModel = {
 type: "category",
 inverse: true,
 axisLabel: {
  show: true,
  textStyle: {
   color: "#fff",
   fontSize: x_Font(),
  },
 },
 splitLine: {
  show: false,
 },
 axisTick: {
  show: false,
 },
 axisLine: {
  show: false,
 },
};
export default {
 props: {
  showType: {
   type: String,
   default: "0",
  },
 },

 data() {
  return {
   hasFlag: true,
   showCharts: false,
   brandRoom: {
    sumBranchNumber: 0,
    sumRoomNumber: 0,
   },
   area: [],
   room: [],
   brands: [],
   operations: [],
   toBeOpeneds: [],
   tryIndustrys: [],
   closeds: [],
   abolishs: [],
   underConstructions: [],
  };
 },
 mounted() {
  console.log(this.nameList);
  this.barCharts = this.$echarts.init(document.getElementById("barCharts"));
  window.addEventListener("resize", () => {
   this.barCharts.resize();
  });
  this.getBrandRoom();
  this.barChartInter = null;
  this.barChartInter = setInterval(() => {
   this.getBrandRoom();
  }, 3600000);
 },
 computed: {
  nameList() {
   let list = this.$store.state.operationState.map((v) => v.lable);

   return list;
  },
 },

 beforeDestroy() {
  clearInterval(this.barChartInter);
 },
 methods: {
  getConfig2() {
   let option = {
    legend: {
     top: "5%",
     data: this.nameList,
     // ["客房数", "运营中", "试业中", "待开业", "筹建中"],
     itemWidth: w_Font() * 2,
     itemHeight: h_Font() * 2,
     textStyle: {
      color: "#fff",
      lineHeight: line_h_Font(),
      fontSize: x_Font(),
     },
    },
    ...barModel,
    yAxis: [
     {
      ...yAxisModel,
      data: this.area,
     },
    ],

    xAxis: [
     {
      type: "value",
      name: "客房数",
      show: false,
     },
     {
      type: "value",
      show: false,
      name: "分店数",
      max:
       this.getMax([
        ...this.operations,
        ...this.toBeOpeneds,
        ...this.tryIndustrys,
        ...this.underConstructions,
        ...this.closeds,
        ...this.abolishs,
       ]) * 3,
     },
    ],
    series: [
     {
      z: 1.5,
      name: "客房数",
      type: "bar",
      data: this.room,
      ...kfModel,
     },
     {
      name: "在营",
      // name: "运营中",
      data: this.operations,
      ...label,
      itemStyle: {
       color: "#2a9d8f",
       fontSize: x_Font(),
      },
     },
     {
      name: "解约",
      // name: "试业中",
      data: this.tryIndustrys,
      ...label,
      itemStyle: {
       color: "#e9c46a",
       fontSize: x_Font(),
      },
     },
     {
      name: "签约",
      // name: "待开业",
      data: this.toBeOpeneds,
      ...label,
      itemStyle: {
       color: "#f4a261",
       fontSize: x_Font(),
      },
     },
     {
      name: "筹建",
      // name: "筹建中",
      data: this.underConstructions,
      ...label,
      itemStyle: {
       color: "#e76f51",
       fontSize: x_Font(),
      },
     },
     {
      name: "停业",
      // name: "筹建中",
      data: this.closeds,
      ...label,
      itemStyle: {
       color: "#84a98c",
       fontSize: x_Font(),
      },
     },
     {
      name: "废止",
      // name: "筹建中",
      data: this.abolishs,
      ...label,
      itemStyle: {
       color: "#cad2c5",
       fontSize: x_Font(),
      },
     },
    ],
    animationEasing: "cubicOut",
   };
   return option;
  },

  getConfig() {
   let option = {
    ...barModel,
    yAxis: [
     {
      ...yAxisModel,
      data: this.area,
     },
    ],
    xAxis: [
     {
      type: "value",
      name: "客房数",
      show: false,
     },
     {
      type: "value",
      show: false,
      name: "分店数",
      max: this.getMax(this.brands) * 2,
     },
    ],
    series: [
     {
      name: "客房数",
      data: this.room,
      ...type1Lable,
      itemStyle: {
       color: "#F59A23",
       fontSize: x_Font(),
      },
     },
     {
      name: "分店数",
      xAxisIndex: 1,
      data: this.brands,
      ...type1Lable,
      itemStyle: {
       color: "#caf982",
       fontSize: x_Font(),
      },
     },
    ],
    animationEasing: "cubicOut",
   };
   return option;
  },

  getMax(list) {
   let maxNum = Math.max(...list);
   return maxNum;
  },
  getType2_MaxVal() {
   concat;
  },
  // 获取分店数及房间数数据
  async getBrandRoom() {
   if (this.showType == 0) {
    try {
     this.hasFlag = true;
     let res = await GET_BRAND_ROOM();
     this.brandRoom = res.data.data;
     this.area = [];
     this.room = [];
     this.brands = [];
     this.showCharts = true;
     res.data.data.brandRegionNumberVos.forEach((item) => {
      this.area.push(item.region);
      this.room.push(item.rooms);
      this.brands.push(item.branchNumber);
     });
    } catch (error) {
     this.hasFlag = false;
    }
    if (this.hasFlag && this.showCharts) {
     this.barCharts.setOption(this.getConfig());
    }
   } else {
    try {
     this.hasFlag = true;
     let res = await findBrandRegionNumber2();
     this.brandRoom = {
      sumBranchNumber: 0,
      sumRoomNumber: 0,
     };
     this.showCharts = true;
     this.area = [];
     this.room = [];
     this.operations = [];
     this.toBeOpeneds = [];
     this.tryIndustrys = [];
     this.underConstructions = [];
     this.closeds = [];
     this.abolishs = [];

     res.data.data.forEach((item) => {
      this.area.push(item.region);
      this.room.push(item.rooms);
      this.operations.push(item.operation); //在营
      this.toBeOpeneds.push(item.toBeOpened); //签约
      this.tryIndustrys.push(item.tryIndustry); //解约
      this.underConstructions.push(item.underConstruction); //筹建
      this.closeds.push(item.closed); //停业
      this.abolishs.push(item.abolish); //废止
      for (let index = 0; index < this.$store.state.times; index++) {
       this.brandRoom.sumBranchNumber += item.operation; //在营
       this.brandRoom.sumBranchNumber += item.toBeOpened; //签约
       this.brandRoom.sumBranchNumber += item.tryIndustry; //解约
       this.brandRoom.sumBranchNumber += item.underConstruction; //筹建
       this.brandRoom.sumBranchNumber += item.closed; //停业
       this.brandRoom.sumBranchNumber += item.abolish; //废止
       this.brandRoom.sumRoomNumber += item.rooms;
      }
     });
    } catch (error) {
     this.hasFlag = false;
    }
    if (this.hasFlag && this.showCharts) {
     this.barCharts.setOption(this.getConfig2());
    }
   }
  },
 },
};
</script>
<style lang="scss" scoped>
@import "@/assets/mdKanban/index.scss";
.barChart .echartNoData::before {
 padding-top: 10vh !important;
}
.barBox {
 width: 100%;
 height: 100%;
 position: relative;
 display: flex;
 flex-direction: column;
 .barChart {
  flex: 1;
 }
}
.bar_tool {
 padding: 2vh 0;
 width: 80%;
 position: absolute;
 top: 0;
 left: 50%;
 transform: translate(-50%);
 a {
  padding: 0 0.4vw;
  outline: none;
  // background: #fff;
  color: #333;
  font-size: 1.5vh;
  line-height: 3vh;
  height: 3vh;
  border-radius: 0.15vw;
  cursor: pointer;
 }
 .shop {
  background: #caf982;
 }
 .room {
  background: #f59a23;
 }
}
#barCharts {
 width: 100%;
 height: 100%;
}
</style>