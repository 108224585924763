<template>
 <div class="demo">
  <!-- <div class="PE" @mouseenter="mouseenterPE" @mouseleave="mouseleavePE">
   <dv-border-box-8 :dur="10">
    <div class="pePadding">

     <div class="PEtitle">
      电能能耗实时统计(度)
      <div class="changeTimeType">
       <span @click="change_PE_Type(1)" :class="{ activeType: PE_type == 1 }">日</span>/<span :class="{ activeType: PE_type == 2 }" @click="change_PE_Type(2)">月</span>
      </div>
     </div>

     <div class="peNum">
      <count-to :start-val="PEtotalOld" :decimals="2" :end-val="PEtotal" :duration="3000" />
     </div>

    </div>
   </dv-border-box-8>
  </div>
  <div class="PE2" @mouseenter="mouseenterPE2" @mouseleave="mouseleavePE">
   <dv-border-box-8 :dur="10">
    <div class="pePadding">

     <div class="PEtitle">
      水能能耗实时统计(t)
      <div class="changeTimeType">
       <span :class="{ activeType: PE_water_type == 1 }" @click="change_PE_WATER_Type(1)">日</span>/<span :class="{ activeType: PE_water_type == 2 }" @click="change_PE_WATER_Type(2)">月</span>
      </div>

     </div>

     <div class="peNum">
      <count-to :start-val="PEwatertotalOld" :decimals="2" :end-val="PEwatertotal" :duration="3000" />
     </div>
    </div>
   </dv-border-box-8>
  </div> -->
  <!-- v-if="showTypeTip" -->
  <!-- <div class="peDetail" v-if="showTypeTip" @mouseenter="mouseenterDETAIL" @mouseleave="mouseleavePE">
   <dv-border-box-8 :dur="5" :reverse="true">
    <div class="detailBar">
     <div class="PEtitle">
      <span>
        {{showTypeTip==1?`${PE_type==1?'每日':'每月'}电能能耗详情`:`${PE_water_type==1?'每日':'每月'}水能能耗详情`}}
        </span>
      
     </div>
     <div class="peTablist">
      <dv-scroll-board :config="config" style="width:100%;height:100%" @click="changeIndex" />
     </div>
    </div>

   </dv-border-box-8>
  </div> -->

  <dv-border-box-11 :title="hotelName" class="mapTitle" :titleWidth="350" v-if="!isPhone">
   <div id="chartMap1" class="chartBar"></div>
  </dv-border-box-11>
  <dv-border-box-13 :reverse="true" v-else>
   <div class="MapTitle">
    <div class="titleName">{{hotelName}}</div>
    <dv-decoration-2 style="width:100%;height:5px" />
   </div>
   <div id="chartMap2" class="chartBar"></div>
  </dv-border-box-13>
 </div>
</template>
<script>
import "echarts/map/js/china.js";
import { getDataMap, isPhone, getDataMap1, showName } from "@/api/common.js";

import {
 GET_BRAND_MAP,
 GET_ProvinceDetails,
 getHotelName,
 findProvinceDetails2,
 powerElectric,
} from "@/api";
import {
 title_Font,
 x_Font,
 map_font_size,
 y_Font,
 w_Font,
 map_top,
 h_Font,
 line_Font,
 symbolSize_Font,
 map_Font,
 map_period,
 map_scale,
 map_shadowBlur,
 mapTip,
 mapTip2,
 map_font_color,
} from "./font";
const geo = {
 map: "china",
 top: map_top(),
 zoom: map_Font(),
 label: {
  normal: {
   show: true,
   textStyle: {
    color: "#ddd",
    // color: "#1DE9B6",
    //  fontWeight:'bold',
    fontSize: map_font_size(),
   },
  },
  emphasis: {
   textStyle: {
    // color: "rgb(183,185,14)",
    // color: "#fff",
    color: "#000",
    fontSize: map_font_size(),
   },
  },
 },
 itemStyle: {
  normal: {
   borderColor: "rgb(147, 235, 248)",
   borderWidth: 1,
   areaColor: {
    type: "radial",
    x: 0.5,
    y: 0.5,
    r: 0.8,
    colorStops: [
     {
      offset: 0,
      color: "#09132c", // 0% 处的颜色
     },
     {
      offset: 1,
      color: "#274d68", // 100% 处的颜色
     },
    ],
    globalCoord: true, // 缺省为 false
   },
  },
  emphasis: {
   //  areaColor: '#1de9b6',
   areaColor: "#23edff",
   borderWidth: 0.1,
  },
 },
};
const toolTipDemo = {
 backgroundColor: "#0A273277",
 padding: 0,
 textStyle: {
  color: "#000",
  decoration: "none",
 },
 position: ["80%", "45%"],
};
import CountTo from "vue-count-to";

export default {
 components: {
  CountTo,
 },
 props: {
  showType: {
   type: String,
   default: "0",
  },
  // titleShowType: {
  //   type: String,
  //   default: "0",
  // },
 },

 data() {
  return {
   showTypeTip: null,
   PEtotalOld: 0,
   PEtotal: 0,
   PE_type: 1,

   PEwatertotalOld: 0,
   PEwatertotal: 0,
   PE_water_type: 1,
   mouseIn: false,
   isPhone: isPhone(),
   mapName:
    this.$route.meta.title == "万润看板"
     ? "粤万润酒店调度平台"
     : "希 尔 顿 欢 朋 酒 店",
   brandMap: [],
   datalist: [],
   electricityDetail: [
    { hotelName: "广州番禺大道希尔顿欢朋酒店", total: 1036 ,averagValue:2},
    { hotelName: "深圳坪山高铁站希尔顿欢朋酒店", total: 936 ,averagValue:2.5},
    { hotelName: "深圳北站希尔顿欢朋酒店", total: 1988 ,averagValue:2.2},
    { hotelName: "贵州仁怀希尔顿欢朋酒店", total: 839 ,averagValue:2.8},
    { hotelName: "广东粤万润科技股份有限公司", total: 169 ,averagValue:3},
    { hotelName: "西安北大街希尔顿欢朋酒店", total: 878 ,averagValue:2.6},
   ],
   waterDetail: [
    { hotelName: "广州番禺大道希尔顿欢朋酒店", total: 565,averagValue:1.1 },
    { hotelName: "深圳坪山高铁站希尔顿欢朋酒店", total: 532,averagValue:0.5 },
    { hotelName: "深圳北站希尔顿欢朋酒店", total: 489,averagValue:1.9 },
    { hotelName: "贵州仁怀希尔顿欢朋酒店", total: 505,averagValue:2.8 },
    { hotelName: "广东粤万润科技股份有限公司", total: 39,averagValue:5 },
    { hotelName: "西安北大街希尔顿欢朋酒店", total: 467,averagValue:1.2 },
   ],
   showTip: "",
  };
 },
 computed: {
  hotelName() {
    return this.showName(this.mapName);
  },
  config() {
   let config = {
    header:
     this.showTypeTip == 1 ? ["门店", "耗电","平均耗电"] : ["门店", "水耗总额(t)","平均水耗(t)"],
    waitTime: 999999,
    rowNum: 7,
    headerHeight: isPhone() ? 13 : 35,
    data: this.getDetails(this.showTypeTip),
    // [["**门店", "3.81"]],
    headerBGC: "#00BAFF77",
    oddRowBGC: "#003B5177",
    evenRowBGC: "#0A273277",
    // index: true,
    columnWidth: isPhone() ? [100] : [220],
    align: ["center", "center","center"],
   };
   return config;
  },
 },
 watch: {
  mouseIn(val) {
   console.log(val, "--------------------");
   if (val) {
    this.PE_DETAIL_TIMEOUT && clearTimeout(this.PE_DETAIL_TIMEOUT);
   } else {
    this.PE_DETAIL_TIMEOUT = setTimeout(() => {
     this.showTypeTip = null;
    }, 1500);
   }
  },
 },
 mounted() {
  if (!this.isPhone) {
   this.chartMap = this.$echarts.init(document.getElementById("chartMap1"));
  } else {
   this.chartMap = this.$echarts.init(document.getElementById("chartMap2"));
  }
  window.addEventListener("resize", () => {
   this.chartMap.resize();
  });
  this.showDemoMap();
  this.getMapData();
  this.MapInter = null;
  this.MapInter = setInterval(() => {
   this.timeTicket && clearInterval(this.timeTicket);
   this.getMapData();
  }, 3600000);
 },
 created() {
  this.getHotelName();
  this.powerElectricInterval();
 },
 beforeDestroy() {
  clearInterval(this.MapInter);
  this.PE_DETAIL_TIMEOUT && clearTimeout(this.PE_DETAIL_TIMEOUT);
  this.PEInterval && clearInterval(this.PEInterval);
  this.timeTicket && clearInterval(this.timeTicket);
 },
 methods: {
  changeIndex(row, ceil, rowIndex) {
    console.log(row, ceil, rowIndex);
  },
  change_PE_Type(val) {
   if (val == this.PE_type) return;
   this.PE_type = val;
  },
  change_PE_WATER_Type(val) {
   if (val == this.PE_water_type) return;
   this.PE_water_type = val;
  },
  getDetails(type) {
   let filterVal = ["hotelName", "total",'averagValue'];
   let data = [];
   if (type == 1) {
    data = this.formatJson(filterVal, this.electricityDetail);
   } else {
    data = this.formatJson(filterVal, this.waterDetail);
   }
   return data;
  },
  mouseleavePE() {
   console.log("移出");
   //  this.showTypeTip = null;
   this.mouseIn = false;
  },
  mouseenterPE() {
   console.log("mouseenterPE");
   this.showTypeTip = 1;
   this.mouseIn = true;
   this.SET_PE_DETAIL_INTERVAL();
  },
  mouseenterPE2() {
   console.log("mouseenterPE2");
   this.showTypeTip = 2;
   this.mouseIn = true;

   this.SET_PE_DETAIL_INTERVAL();
  },
  mouseenterDETAIL() {
   this.mouseIn = true;
  },
  SET_PE_DETAIL_INTERVAL() {
   this.PE_DETAIL_INTERVAL && clearInterval(this.PE_DETAIL_INTERVAL);
   this.PE_DETAIL_INTERVAL = setInterval(() => {
    if (this.showTypeTip == 1) {
    } else if (this.showTypeTip == 2) {
    }
   }, 5000);
  },
  formatJson(filterVal, jsonData) {
   return jsonData.map((v) => filterVal.map((j) => v[j]));
  },

  powerElectricReq() {
   powerElectric().then((res) => {
    if (res.data.code == "000000") {
     this.PEtotalOld = this.PEtotal;
     this.PEwatertotalOld = this.PEtotal;
     setTimeout(() => {
      // this.PEtotal = res.data.data;
      this.PEtotal = 0;
      this.PEwatertotal = 0;
      this.electricityDetail.forEach((item) => {
       this.PEtotal += item.total;
      }, 0);
      this.waterDetail.forEach((item) => {
       this.PEwatertotal += item.total;
      }, 0);
     }, 2000);
    }
   });
  },
  powerElectricInterval() {
   this.PEInterval = setInterval(() => {
    this.powerElectricReq();
   }, 5000);
  },
  mapData() {
   return {
    type: "map",
    top: map_top(),
    mapType: "china",
    zoom: map_Font(),
    label: {
     normal: {
      show: true,
      textStyle: {
       color: map_font_color(),
       fontSize: map_font_size(),
      },
     },
     emphasis: {
      textStyle: {
       color: "#fff",
       fontWeight: "bold",
       fontSize: map_font_size() + 2,
      },
     },
    },
    itemStyle: {
     normal: {
      borderColor: "rgb(147, 235, 248)",
      borderWidth: 1,
      areaColor: {
       type: "radial",
       x: 0.5,
       y: 0.5,
       r: 0.8,
       colorStops: [
        {
         offset: 0,
         color: "#09132c", // 0% 处的颜色
        },
        {
         offset: 1,
         color: "#274d68", // 100% 处的颜色
        },
       ],
       globalCoord: false, // 缺省为 false
      },
     },
     emphasis: {
      areaColor: "#2ebaff",
      borderWidth: 1,
     },
    },
    data: this.datalist,
   };
  },
  getHotelName() {
   if (this.$route.meta.title != "万润看板") {
    if (this.showType == 0) {
     getHotelName().then((res) => {
      if (res.data.code == "000000" && res.data.data) {
       console.log("---------------", res.data.data);
       if (res.data.data.indexOf("希尔顿欢朋") != -1) {
        this.mapName = res.data.data;
       }
      }
     });
    }
   }
  },
  showName(val) {
   return showName(val, "title");
  },
  async getMapData() {
   let arr = [];
   try {
    let res = await GET_BRAND_MAP();
    this.brandMap = [];
    res.data.data.forEach((item) => {
     this.brandMap.push({
      name: item.cityName,
      value: [+item.lng, +item.lat, +item.value],
     });
    });
   } catch (error) {}
   // 地理坐标
   if (this.showType == 0) {
    // 原省份信息（即 运营中试业中 待开业 筹建中状态的数量）
    try {
     let resData = await GET_ProvinceDetails();
     if (resData.data.code == "000000") {
      arr = resData.data.data;
     }
    } catch (error) {}
    this.datalist = getDataMap(arr);
   } else {
    // 运营分店数、客房数、取电房间数
    try {
     let resData = await findProvinceDetails2();
     if (resData.data.code == "000000") {
      arr = resData.data.data;
     }
    } catch (error) {}
    console.log(arr);
    this.datalist = getDataMap1(arr);
   }
   // this.chartMap.hideLoading()
   this.showMap();
  },
  showDemoMap() {
   var option = {
    tooltip: {
     ...toolTipDemo,
     show: false,
    },
    geo: geo,
    series: [this.mapData()],
   };
   console.log(option, "-------------showDemoMap-----option");
   this.chartMap.setOption(option, true);
  },
  showMap() {
   var that = this;
   var option = {
    tooltip: {
     ...toolTipDemo,
     show: true,
     formatter: function (params) {
      if (params.data) {
       if (that.showType == 0) {
        return mapTip(params);
       } else {
        return mapTip2(params);
       }
      }
     },
    },
    geo: geo,
    series: [
     this.mapData(),
     {
      type: "effectScatter",
      coordinateSystem: "geo",
      rippleEffect: {
       period: map_period(),
       // map_period(),
       scale: map_scale(),
       // map_scale(),
       brushType: "fill",
      },
      itemStyle: {
       normal: {
        fontSize: map_font_size(),
        color: "#d6b41a", // d6b41a、 #00f5f1  \#00ff7d #00dcff
        shadowBlur: 10,
        shadowOffsetY: 1,
        // shadowBlur: map_shadowBlur(),
        shadowColor: "#333",
       },
      },
      symbolSize: symbolSize_Font(),
      // map_font_size(),
      tooltip: {
       backgroundColor: "#0A273277",
       textStyle: {
        fontSize: x_Font(),
        color: "#fff", //设置文字颜色
       },
       position: function (point, params, dom) {
        return [point[0] + 30, point[1]];
       },
       formatter: function ({ data }) {
        return data.name + ": " + data.value[2];
       },
      },
      data: this.brandMap,
     },
    ],
   };
   console.log(option, "------------------option");
   this.chartMap.setOption(option, true);
   this.IntervalToolTipSet(option);
  },
  // 定时轮播tooltip设置
  IntervalToolTipSet(option) {
   var count = 0;
   this.timeTicket = null;
   var dataLength = option.series[0].data.length;
   this.timeTicket && clearInterval(this.timeTicket);
   this.timeTicket = setInterval(() => {
    this.chartMap.dispatchAction({
     type: "downplay",
     seriesIndex: 0,
    });
    this.chartMap.dispatchAction({
     type: "highlight",
     seriesIndex: 0,
     dataIndex: count % dataLength,
    });
    this.chartMap.dispatchAction({
     type: "showTip",
     seriesIndex: 0,
     dataIndex: count % dataLength,
    });
    count++;
   }, 2000);

   this.chartMap.on("mouseover", (params) => {
    clearInterval(this.timeTicket);
    this.chartMap.dispatchAction({
     type: "downplay",
     seriesIndex: 0,
    });
    this.chartMap.dispatchAction({
     type: "highlight",
     seriesIndex: 0,
     dataIndex: params.dataIndex,
    });
    this.chartMap.dispatchAction({
     type: "showTip",
     seriesIndex: 0,
     dataIndex: params.dataIndex,
    });
   });
   this.chartMap.on("mouseout", (params) => {
    this.timeTicket && clearInterval(this.timeTicket);
    this.timeTicket = setInterval(() => {
     this.chartMap.dispatchAction({
      type: "downplay",
      seriesIndex: 0,
     });
     this.chartMap.dispatchAction({
      type: "highlight",
      seriesIndex: 0,
      dataIndex: count % dataLength,
     });
     this.chartMap.dispatchAction({
      type: "showTip",
      seriesIndex: 0,
      dataIndex: count % dataLength,
     });
     count++;
    }, 2000);
   });
  },
  resize() {
   this.chartMap.resize();
  },
 },
};
</script>


<style lang="scss" scoped>
// @import "@/assets/mdKanban/index.scss";
// @import "@/assets/mdKanban/listData.scss";
// .chartBar /deep/ .dv-border-box-11-title {
//   font-size: 20vh !important;
// }
.PE {
 position: absolute;
 top: 5vh;
 text-align: center;
 left: 1.8vw;
 color: #fff;
 cursor: pointer;
 z-index: 999;
}
.PE2 {
 position: absolute;
 bottom: 3vh;
 text-align: center;
 left: 1.8vw;
 color: #fff;
 cursor: pointer;
 z-index: 999;
}
.peDetail {
 position: absolute;
 top: 18vh;
 bottom: 16vh;
 //  text-align: center;
 left: 1.8vw;
 background-color: rgba(0, 0, 0, 0.4);
 color: #fff;
 z-index: 999;
 .peTablist {
  //  padding: 0 0.8vw;
  font-size: 1.6vh;
  height: calc(100% - 6vh);
 }
}
.detailBar {
 padding: 0 1vw;
 height: 100%;
 width: 23vw;
 .PEtitle {
  height: 5vh;
  line-height: 5vh;
  padding: 0 1vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
 }
}
.changeTimeType {
 position: absolute;
 right: 0.2vw;
 top: 50%;
 transform: translateY(-50%);
 font-size: 0.6vh;
 height: 100%;
 line-height: 2.5vh;
 span {
  padding: 0 0.2vw;
 }
}

.pePadding {
 //  padding: 2vh 0;
 //  min-width: 12vw;
 padding: 1.5vh 0;
 min-width: 12vw;
}
.PEtitle {
 font-size: 1.8vh;
 line-height: 2.5vh;
 position: relative;
 height: 2.5vh;
 text-align: left;
 padding-left: 0.6vw;
}
.activeType {
 color: #79bbff;
 font-weight: 600;
}
.peNum {
 padding: 1.5vh 0 1vh;
 line-height: 4vh;
 font-size: 3.5vh;
}
.MapTitle {
 // width: vw;
 width: 30vh;
 padding-top: 1vh;
 height: 5vh;
 position: absolute;
 top: 17px;
 left: 50%;
 transform: translateX(-50%);
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 align-items: center;
 .titleName {
  padding: 0 1vw;
  white-space: nowrap;
  font-weight: 600;
  font-size: 3vh;
  //  position: absolute;
  // top: 0;
  // left: 50%;
  // transform: translateX(-50%);
  color: #fff;
 }
}
.demo {
 width: 100%;
 height: 100%;
 position: relative;
}
.chartBar {
 width: 100%;
 height: 100%;
}
.showTip {
 position: absolute;
 right: 20px;
 top: 50%;
 transform: translateY(-50%);
}
</style>