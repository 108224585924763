var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "demo" },
    [
      !_vm.isPhone
        ? _c(
            "dv-border-box-11",
            {
              staticClass: "mapTitle",
              attrs: { title: _vm.hotelName, titleWidth: 350 },
            },
            [_c("div", { staticClass: "chartBar", attrs: { id: "chartMap1" } })]
          )
        : _c("dv-border-box-13", { attrs: { reverse: true } }, [
            _c(
              "div",
              { staticClass: "MapTitle" },
              [
                _c("div", { staticClass: "titleName" }, [
                  _vm._v(_vm._s(_vm.hotelName)),
                ]),
                _c("dv-decoration-2", {
                  staticStyle: { width: "100%", height: "5px" },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "chartBar", attrs: { id: "chartMap2" } }),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }