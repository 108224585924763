var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "demo" },
    [
      _c("dv-border-box-1", [
        _c("div", { staticClass: "barBox" }, [
          _c("div", { staticClass: "flex bar_tool flex-between" }, [
            _c("a", { staticClass: "shop" }, [
              _vm._v("分店数 " + _vm._s(_vm.brandRoom.sumBranchNumber)),
            ]),
            _c("a", { staticClass: "room" }, [
              _vm._v("客房数 " + _vm._s(_vm.brandRoom.sumRoomNumber)),
            ]),
          ]),
          _c("div", { staticClass: "barChart" }, [
            _c("div", {
              class: { echartNoData: !_vm.hasFlag && !_vm.showCharts },
              attrs: { id: "barCharts" },
            }),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }